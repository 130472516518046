<template>
    <el-dialog :visible.sync="visible" width="30%" :show-close="false" class="register-dialog resetPsd-dialog" 
        center  :before-close="closeResetPsd">
        <span slot="title">{{title}}</span>
        <el-divider></el-divider>
        <div>
            <el-form :model="brandForm" :rules="brandFormRules" ref="brandForm" label-width="120px">
                <el-form-item label="品牌代码:" prop="brandCode">
                    <el-input class="register-input" v-model="brandForm.brandCode" size="small"></el-input>
                </el-form-item>
                <el-form-item label="品牌中文名称:" prop="cnName">
                    <el-input class="register-input" v-model="brandForm.cnName" size="small"></el-input>
                </el-form-item>
                <el-form-item label="品牌英文名称:" prop="enName">
                    <el-input class="register-input" v-model="brandForm.enName" size="small"></el-input>
                </el-form-item>
                <el-form-item label="机会点图标:" prop="siteIcon">
                    <el-upload
                        style="display:inline-block;"
                        class="avatar-uploader"
                        :action="imageURL"
                        :headers="headersObj"
                        :show-file-list="false"
                        :on-success="handleSiteSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="siteIcon" :src="siteIcon" class="avatar1">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <div slot="tip" class="el-upload__text">15*15像素，不大于20K</div>
                    </el-upload>
                </el-form-item>
                <el-form-item label="门店图标:" prop="storeIcon">
                    <el-upload
                        style="display:inline-block;"
                        class="avatar-uploader"
                        :action="imageURL"
                        :headers="headersObj"
                        :show-file-list="false"
                        :on-success="handleStoreSuccess"
                        :before-upload="beforeAvatarUpload">
                        <img v-if="storeIcon" :src="storeIcon" class="avatar1">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        <div slot="tip" class="el-upload__text">15*15像素，不大于20K</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <el-row style="text-align:center">
                <el-button style="width: 40%;margin-right:20px;" @click="closeResetPsd">取消</el-button>
                <el-button type="primary" style="width: 40%" @click="saveBrandInfo('brandForm')">保存</el-button>
            </el-row>
        </div>
    </el-dialog>
</template>
<script>
import api from "@/api/index.js";
import md5 from 'js-md5';
import constants from "@/constants/Map.constants.js";
export default {
    name: "BrandDialog",
    props: ["isShowBrandDialog", "brandID"],
    data(){
        return {
            visible: false,
            brandForm: {
                brandCode: "", // 品牌代码
                cnName: "", // 品牌中文名
                enName: "", // 品牌英文名
                siteIcon: "", // 机会点图标
                storeIcon: "", // 门店图标
                cusNo: "", // 客户编码
                creator: "" // 操作人
            },
            brandFormRules: {
                brandCode: [{required: true, message: '品牌代码不能为空', trigger: 'blur' }],
                cnName: [{required: true, message: '品牌中文名称不能为空', trigger: 'blur' }],
                enName: [{required: true, message: '品牌英文名称不能为空', trigger: 'blur' }],
                siteIcon: [{required: true, message: '机会点图标不能为空', trigger: 'blur' }],
                storeIcon: [{required: true, message: '门店图标不能为空', trigger: 'blur' }]
            },
            title: "新增品牌",
            imageURL: "",
            headersObj: {},
            siteIcon: "",
            storeIcon: ""
        }
    },
    mounted(){
        this.visible = this.isShowBrandDialog;
        this.brandForm.cusNo = sessionStorage.cusNo;
        this.brandForm.creator = sessionStorage.userName;
        this.imageURL = this.formatURL("common/uploadImage");
        this.headersObj = {
            "time": new Date().getTime(),
            "token": sessionStorage.token || "",
            "timestamp": sessionStorage.timestamp || ""
        }
    },
    methods:{
        confirmResetPsd(formName){
            this.visible = false;
            this.$emit("update-brand",this.visible);
        },
        closeResetPsd(){
            this.visible = false;
            this.$emit("update-brand",this.visible);
        },
        handleStoreSuccess(res, file) {
            this.$message({
                message: res.data.message,
                type: "success"
            });
            this.storeIcon = this.formatURL(res.data.url);
            this.brandForm.storeIcon = res.data.url;
        },
        handleSiteSuccess(res, file) {
            this.$message({
                message: res.data.message,
                type: "success"
            });
            this.siteIcon = this.formatURL(res.data.url);
            this.brandForm.siteIcon = res.data.url;
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024  < 20;

            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 20KB!');
            }
            return isLt2M;
        },
        formatURL(url) {
            let resUrl = "";
            if(url) {
                resUrl = constants.baseURL.API + url;
            }
            return resUrl;
        },
        saveBrandInfo(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.title == "新增品牌") {
                        api.insertCusbrand(this.brandForm).then(res => {
                            this.resposeData(res);
                        }).catch(err => {
                            this.$message.error("后台程序出错，请联系管理员处理");
                        });
                    } else {
                        api.updateCusbrand(this.brandForm).then(res => {
                            this.resposeData(res);
                        }).catch(err => {
                            this.$message.error("后台程序出错，请联系管理员处理");
                        });
                    }
                } else {
                    return false;
                }
            });
        },
        resposeData(res) {
            if(res.code == 200) {
                this.$message({
                    type: "success",
                    message: "保存成功"
                });
                this.visible = false;
                this.$emit("update-brand",this.visible);
            } else {
                this.$message.error("后台程序出错，请联系管理员处理");
            }
        }
    },
    watch:{
        isShowBrandDialog(val){
            this.visible = val;
            if(this.brandID != 0) {
                this.brandForm = val;
                this.siteIcon = this.formatURL(val.siteIcon);
                this.storeIcon = this.formatURL(val.storeIcon);
            } else {
                this.brandForm = {
                    brandCode: "", // 品牌代码
                    cnName: "", // 品牌中文名
                    enName: "", // 品牌英文名
                    siteIcon: "", // 机会点图标
                    storeIcon: "", // 门店图标
                    cusNo: sessionStorage.cusNo, // 客户编码
                    creator: sessionStorage.userName // 操作人
                };
                this.siteIcon = "";
                this.storeIcon = "";
            }
        },
        brandID(val) {
            this.title = (val == 0 ? "新增品牌" : "编辑品牌");
            if(val != 0) {
                this.brandForm = val;
                this.siteIcon = this.formatURL(val.siteIcon);
                this.storeIcon = this.formatURL(val.storeIcon);
            } else {
                this.brandForm = {
                    brandCode: "", // 品牌代码
                    cnName: "", // 品牌中文名
                    enName: "", // 品牌英文名
                    siteIcon: "", // 机会点图标
                    storeIcon: "", // 门店图标
                    cusNo: sessionStorage.cusNo, // 客户编码
                    creator: sessionStorage.userName // 操作人
                };
                this.siteIcon = "";
                this.storeIcon = "";
            }
        } 
    }
}
</script>
<style>
.resetPsd-dialog .el-divider--horizontal{
    margin-top: 10px; 
    margin-left: -25px;
    padding-left: 50px;
}
.register-dialog .avatar-uploader .el-upload.el-upload--text {
    width: 30px;
    height: 34px;
}
.register-dialog .avatar-uploader .avatar-uploader-icon {
    width: 30px;
    height: 34px;
    line-height: 34px !important;
}
</style>