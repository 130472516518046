<template>
    <div class="system-container">
        <el-row>
            <el-row style="margin-bottom: 2px;">
                <el-button type="primary" size="small" @click="addBrandDetail">增加品牌</el-button>
            </el-row>
            <el-row class="brand-content" :style="contentStyle">
                <el-col :span="6" v-for="(item,index) in brandList" :key="index" class="brand-card">
                    <el-card>
                        <el-row>
                            <el-col :offset="23">
                                <el-tooltip class="item" effect="dark" content="删除" placement="bottom">
                                    <el-popconfirm
                                        title="确定删除该品牌吗？"
                                        @onConfirm="delBrand(item)"
                                    >
                                        <el-link  slot="reference" icon="el-icon-circle-close" :underline="false"></el-link>
                                        <!-- <el-button>删除</el-button> -->
                                    </el-popconfirm>
                                </el-tooltip>
                            </el-col>
                        </el-row>
                        <el-row >
                            <div @click="editBrandDetail(item)">
                                <el-form label-width="120px">
                                    <el-form-item label="品牌中文名称：">
                                        <span>{{item.cnName}}</span>
                                    </el-form-item>
                                    <el-form-item label="品牌英文名称：">
                                        <span>{{item.enName}}</span>
                                    </el-form-item>
                                    <el-form-item label="机会点图标：">
                                        <img v-if="item.siteIcon" :src="formatURL(item.siteIcon)" class="avatar1">
                                    </el-form-item>
                                    <el-form-item label="门店图标：">
                                        <img v-if="item.storeIcon" :src="formatURL(item.storeIcon)" class="avatar1">
                                    </el-form-item>
                                </el-form>
                            </div>
                        </el-row>
                    </el-card>
                </el-col>
            </el-row>
        </el-row>
        <BrandDialog :isShowBrandDialog="showBrandDialog" :brandID="brandID" @update-brand="updateBrand"></BrandDialog>
    </div>
</template>

<script>
import constants from "@/constants/Map.constants.js";
import api from "@/api/index.js";
import BrandDialog from "@/components/Dialog/Info/BrandDialog.vue";
const gap = 100;
export default {
    name: "companyMaintain",
    data() {
        return {
            imageURL: "",
            contentStyle: {
                maxHeight: ""
            },
            showBrandDialog: false,
            brandID: 0,
            cusNo: "",
            brandList: []
        }
    },
    components: {
        BrandDialog
    },
    created() {
        this.getCompany();
    },
    mounted(){
        this.imageURL = this.formatURL("common/uploadImage");
       
        // this.contentStyle = {
        //     maxHeight: (this.$store.state.screenHeight - 100) + "px"
        // };
        this.cusNo = sessionStorage.cusNo;
        
    },
    methods: {
        // 获取品牌列表
        getCompany() {
            api.getCompany({
                cusNo: sessionStorage.cusNo
            }).then(res => {
                if(res.code == 200) {
                    this.brandList = res.data;
                } else {
                    this.$message.error("后台程序出错，请联系管理员处理");
                }
            }).catch(err => {
                console.log(err);
                this.$message.error("后台程序出错，请联系管理员处理");
            })
        },
        //新增品牌
        addBrandDetail() {
            this.showBrandDialog = true;
            this.brandID = 0;
        },
        // 编辑品牌详情
        editBrandDetail(item) {
            this.showBrandDialog = true;
            this.brandID = item;
        },
        //删除品牌
        delBrand(item) {
            api.deleteCusbrand({
                brandId: item.brandId
            }).then(res => {
                if(res.code == 200) {
                    this.getCompany();
                } else {
                    this.$message.error("删除失败");
                }
            })
        },
        //更新弹框状态
        updateBrand(val) {
            this.showBrandDialog = val;
            this.brandID = 0;
            if(this.showBrandDialog == false) {
                this.getCompany();
            }
        },
        formatURL(url) {
            let resUrl = "";
            if(url) {
                resUrl = constants.baseURL.API + url;
            }
            return resUrl;
        }
    },
    watch: {
        "$store.state.screenHeight":function(value){
            this.contentStyle = {
                maxHeight: (value - 0) + "px"
            };
        }
    }
}
</script>
<style>
.brand-card {
    cursor: pointer;
    padding: 0 10px 10px 0;
}
.brand-card>.el-card:hover {
    background: #f9f9f9;
}
.brand-content {
    border-top: 1px solid #ddd;
    padding-top: 20px;
    max-height: 591px;
    overflow-y: auto;
    margin-right: -30px;
}
.brand-card .el-form-item {
    margin-bottom: 5px;
}
.brand-card .el-form-item__label {
    font-size: 12px;
}
.system-container {
    font-size: 13px;
    padding: 10px 30px;
}
.system-text {
    font-size: 13px;
}
.upload-demo {
    display: inline-block;
}
.upload-btn {
    position: relative;
}
.system-container .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    /* overflow: hidden; */
  }
  .system-container .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .system-container .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    /* width: 178px;
    height: 178px; */
    line-height: 178px;
    text-align: center;
  }
  .system-container .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
  .system-container .avatar1 {
    width: 30px;
    height: 34px;
    display: block;
  }
  .companyIcon {
      background: #eee;
      width: 340px;
      height: 200px;
      margin-top: 20px;
      padding: 20px;
  }
</style>
